import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import Link from '@shared/ui/navigation/Link';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import ItemsList from '@shared/ui/display/ItemsList';
import Button from '@shared/ui/buttons/Button';
import { BodyMediumSemiBold } from '@components/styled/Typography';
import { ContainerColumn } from '@components/styled';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import { EXCHANGE_NAMES } from '@shared/ui/sidebars/constants';
import { StepContainer, StepText, StepTitle } from '../shared';
import useConnectExchange from '../useConnectExchange';
const HintsContainer = styled.div(props => ({
    background: '#F8FAFF',
    padding: props.theme.spacing_sizes.xm,
}));
const LinkHintText = styled(BodyMediumSemiBold)(() => ({
    color: '#7E868C',
    lineHeight: '17px',
}));
const LinkContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
    height: 'auto',
    textAlign: 'center',
    lineHeight: '17px',
    fontWeight: 500,
}));
const ItemsListStyled = styled(ItemsList)(() => ({
    '& li.MuiListItem-root': {
        padding: 0,
    },
}));
const ConnectExchange = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { handleClose, goToNextStep, goToPrevStep, sidebarFormValues, } = useConnectExchange();
    const selectedExchange = (sidebarFormValues &&
        sidebarFormValues.exchange !== null) ?
        EXCHANGE_NAMES[sidebarFormValues.exchange] :
        { name: '', creationLink: '' };
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: handleClose }, title: (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.connect_exchange.back_btn') })), padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, displayCancelBtn: true, children: _jsx(StepContainer, { title: (_jsx(StepTitle, { children: Trans({
                    i18nKey: 'overview.connect_exchange.connect_exchange.title',
                    values: {
                        title: selectedExchange.name,
                    },
                }) })), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { text: t('overview.connect_exchange.connect_exchange.text'), stepNumber: t('overview.connect_exchange.connect_exchange.step') }), _jsx(HintsContainer, { children: _jsx(ItemsListStyled, { list: t('overview.connect_exchange.connect_exchange.instruction.binance', { returnObjects: true }), itemPadding: '0', listStyle: 'none' }) })] })), contentJustify: 'start', containerGap: theme.spacing_sizes.s * 10, contentFlex: 1, buttons: (_jsxs(_Fragment, { children: [_jsxs(LinkContainer, { children: [_jsx(LinkHintText, { children: t('overview.connect_exchange.connect_exchange.hint') }), _jsx(Link, { href: selectedExchange.creationLink, children: Trans({
                                    i18nKey: 'overview.connect_exchange.connect_exchange.lint_text',
                                    values: {
                                        title: selectedExchange.name,
                                    },
                                }) })] }), _jsx(Button, { size: 'large', fullWidth: true, onClick: () => goToNextStep(), children: Trans({
                            i18nKey: 'overview.connect_exchange.connect_exchange.continue_btn',
                            values: {
                                title: selectedExchange.name,
                            },
                        }) })] })), buttonsGap: theme.spacing_sizes.xs * 2.5 }) }));
};
export default ConnectExchange;
